//import logo from './logo.svg';
import logo from './new_logo.jpg';
import questionGIF from './images/NFT.gif';
import duckboss from './images/duckboss.png';
import duckelon from './images/duckelon.png';
import duckolo from './images/duckolo.png';
import duckmuck from './images/duckmuck.png';
import './App.css';
import PageModal from './components/PageModal/PageModal.js';
import FAQ from './components/FAQ/FAQ.js';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import Countdown, { zeroPad } from 'react-countdown';


// Random component
const Completionist = () => <span>Countdown will start soon!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <Grid container spacing={3} style={{fontSize: '24px', fontWeight: 'bold'}}> 
             <Grid item xs={3}>{zeroPad(days, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>days</span></Grid> 
             <Grid item xs={3}>{zeroPad(hours, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>hours</span></Grid>
             <Grid item xs={3}>{zeroPad(minutes, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>minutes</span></Grid>
             <Grid item xs={3}> {zeroPad(seconds, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>seconds</span></Grid> 
           </Grid>;
  }
};

function App() {
  return (
    <div className="App">
      <header className="App-header">

        

        <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} >
            <img src={logo} className="App-logo" alt="logo" />
            <h1>
              FreakyDuckClub
            </h1>
            <p style={{lineHeight: "1.8", fontSize: "18px"}}>10,000 Freaky Duck NFTs living on the Solana Blockchain! We are currently in our Sneak Peek Phase! More full previews are available on Discord 👀</p>
            
            <Button variant="contained" color="primary" target="_blank" href="https://discord.gg/YYC2QS9t">
              Join our Discord & See previews!
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>

            <div className="wallet-selection">
              <p className="title-wallet">10,000 NFTs Remaining</p>
              <p className="title-wallet-sub">Dropping Date coming soon</p>
              {/*<div className="countdown-area">
                <Countdown
                  date={'2021-09-08 21:00:00'}
                  renderer={renderer}
                  zeroPadTime={2}
                />
              </div>*/}
              <img src={questionGIF} className="question-gif"/>
              <p className="title-wallet-bold"> Our NFTs are X SOL each</p>

            </div>
            
          </Grid>
        </Grid>
        </Container>

      </header>

      <section className="team-section">
        <h1>The Team</h1>
        <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={duckboss}/>
              <h3>Duckboss</h3>
              <h5>Artist</h5>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={duckelon}/><h3>Duckelon</h3>
              <h5>Programmer</h5>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={duckolo}/><h3>Duckolo</h3>
              <h5>Community</h5>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={duckmuck}/><h3>Duckmuck</h3>
              <h5>Marketing Pro</h5>
            </div>
          </Grid>
        </Grid>
        </Container>
      </section>
      <section className="faq-section">
        <h1 style={{color: "white"}}>FAQ</h1>
        <Container maxWidth="sm">
          <FAQ />
        </Container>
      </section>
      <footer>
        <PageModal />
      </footer>
    </div>
  );
}

export default App;
