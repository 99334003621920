import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordion: {
    backgroundColor: '#273c75',
    color: "white"
  }
}));

export default function FAQ() {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>What are Freaky Ducks?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            10,000 randomly generated carefully handcrafted freaky :duck: NFTs living on the Solana Blockchain
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>When can I mint a Freaky Duck?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We haven't set a fixed launch date yet, as the club is still building up and all freaky ducks joined the club
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>What is the mint price?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SOL bull run is going crazy. We will carefully watch the development and announce the price soon
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Where can I get my freaky ducks?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our mint will take place here on this website. You will be able to connect with your favorite Solana wallet and then mint your NFTs!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>What about secondary marketplaces?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We are currently working on being listed on digital eyes where you'd be able to trade your freaky ducks
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>What about refunds & how long do they take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            In contrast to many other NFT projects where you had to wait for refunds etc., our smart contract is designed in a way that once all NFTs have been minted, we do not accept any new transactions and thus SOL won't leave your wallet & no refunds are needed!
          </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
  );
}